var ACTIVITY_STATUS_VALUES = [
    "not started",
    "on track",
    "at risk",
    "completed",
    "not applicable",
];
var SOLUTION_TYPES = ["Local", "Target", "Local & Target"];

var DIMENSIONS = [
    "Governance",
    "Awareness",
    "Processes",
    "Technology",
];

var FORMATTED_MATURITY = [
    "Very Low (1)",
    "Low (2)",
    "Medium (3)",
    "High (4)",
    "Very High (5)",
];

var MATURITIES = [0, 1, 2, 3];

var OERegions = [
    "Shared Services",
    "AZ Technology",
    "Europe 1",
    "Europe 2",
    "Europe 3",
    "AZAP",
    "Asset & Overseas",
    "CEE",
    "German Market",
    "Africa",
    "MENA",
    "IberoLatAm",
];

var PRIORITIES = ["maturity 1", "maturity 2", "maturity 3"];

var ADMIN_GROUP_NAME = "admin";

export { ACTIVITY_STATUS_VALUES, ADMIN_GROUP_NAME, DIMENSIONS, FORMATTED_MATURITY, MATURITIES, OERegions, PRIORITIES, SOLUTION_TYPES };
