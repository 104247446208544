import {
  View,
  type App,
  type Plugin,
  EventEmitter,
  UniversalAppState,
} from "@pimo/pimo-app-builder";
import { APP_ROUTES } from "../constants";
import { fetchOEProjects } from "../helpers/dlp-fetch-helper";
import { isUserAdmin } from "../helpers/is-user-admin";
import { Profile } from "dlp-types";

export class HomePlugin<
    AppState extends UniversalAppState & {
      isLoggedIn?: boolean;
      userProfile?: Profile;
    },
  >
  extends EventEmitter<"registered">
  implements Plugin<AppState, AppState>
{
  route?: Plugin<AppState, AppState>["route"];

  onRegister(app: App<AppState>): void {
    this.route = app.createRoute({
      path: "/",
      view: new View("home", { getLayoutComponent: () => () => null }),
    });
    this.route.on("load", async () => {
      const isLoggedIn = app.getAppState().isLoggedIn;

      if (isLoggedIn) {
        // if route is loaded and user is not admin, redirect them to the first OE report
        if (!isUserAdmin(app.getAppState().userProfile)) {
          if (app.getAppState().userProfile?.regionsUserHasAccessTo?.length) {
            app.navigate(APP_ROUTES.dashboard);
          } else {
            const oeProjects = await fetchOEProjects();
            if (oeProjects.length > 1) {
              app.navigate(APP_ROUTES.overview);
            } else if (oeProjects.length > 0) {
              app.navigate(`/reports/${oeProjects?.[0]?.id ?? 0}/dashboard`);
            } else {
              app.navigate(`/no-data`);
            }
          }
        } else {
          app.navigate(APP_ROUTES.dashboard);
        }
      } else {
        app.setAppState({ ...app.getAppState(), isLoading: false });
        app.navigate(APP_ROUTES.login);
      }
    });
  }
}
