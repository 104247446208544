import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

interface CheckBoxFieldProps {
  /** form element name */
  name: string;
  /** label text */
  label: string;
  /** on change listener */
  onChange?: (checked: boolean) => void;
}

export const CheckBoxField: FC<CheckBoxFieldProps> = ({
  name,
  label,
  onChange,
}) => {
  const { control, watch } = useFormContext();

  return (
    <div data-testid="CheckboxFormField">
      <FormControl sx={{ height: "100%" }}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!watch(name)}
                  onChange={({ target }) => {
                    field.onChange(target.checked);
                    onChange?.(target.checked);
                  }}
                />
              }
              label={label}
            />
          )}
        />
      </FormControl>
    </div>
  );
};
