import {
  Box,
  BoxProps,
  Chip,
  Typography,
  TypographyProps,
  useTheme,
} from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import type { PropsWithChildren } from "react";

export type SectionHeaderWithMultipleBadgesProps = PropsWithChildren<{
  badges: {
    title?: string;
    badgeTextContent?: string;
    chipColor?: string;
    textColor?: string;
    textProps?: TypographyProps;
  }[];

  endSlotChipDetails?: {
    label?: string;
    value?: string;
  };
}> &
  BoxProps;

export const SectionHeaderWithMultipleBadges: PimoReactComponent<
  SectionHeaderWithMultipleBadgesProps
> = ({
  // to prevent an error, which is shown in the browser console, we're destructuring `fireEvent`
  // eslint-disable-next-line
  fireEvent,
  badges,
  endSlotChipDetails,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Box
      {...props}
      data-testid="SectionHeader"
      sx={{
        alignItems: "center",
        backgroundColor: theme.palette.primary.main,
        borderRadius: 6,
        color: theme.palette.common.white,
        display: "flex",
        height: 36,
        maxHeight: 36,
        overflow: "auto",
        justifyContent: "space-between",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        "@media print": {
          pageBreakBefore: "always",
        },
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 5,
          alignItems: "center",
        }}
      >
        {badges.map((badge) => (
          <Box key={badge.title} sx={{ display: "flex", flexDirection: "row" }}>
            <Typography
              sx={{
                fontWeight: theme.typography.fontWeightBold,
                ...badge.textProps?.sx,
                overflow: "hidden",
                lineHeight: "1.4rem",
                maxHeight: "1.2rem",
                textOverflow: "ellipsis",

                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
              }}
            >
              {badge.title}
            </Typography>
            {badge.badgeTextContent && (
              <Chip
                label={badge.badgeTextContent}
                sx={{
                  lineHeight: "1.5rem",
                  height: "1.5rem",
                  backgroundColor: badge.chipColor,
                  color: badge.textColor,
                  marginLeft: theme.spacing(1),
                }}
              />
            )}
          </Box>
        ))}
      </Box>

      {endSlotChipDetails && (
        <Box sx={{ display: "flex", flexDirection: "row", fontWeight: 500 }}>
          <Typography>{endSlotChipDetails.label}</Typography>
          <Chip
            label={endSlotChipDetails.value}
            sx={{
              lineHeight: "1.5rem",
              height: "1.5rem",
              backgroundColor: "#B5DAE6",
              color: "black",
              marginLeft: theme.spacing(1),
            }}
          ></Chip>
        </Box>
      )}
    </Box>
  );
};

export default SectionHeaderWithMultipleBadges;
