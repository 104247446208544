import { Cache } from "@pimo/pimo-app-builder";
import {
  Attachment,
  Dashboard,
  FilterData,
  OEProjectAttributes,
  OEProjectAttributesForEditPage,
  OEProjectOverview,
  Profile,
  ProgramAPIAttributes,
  ProgramAttributes,
  SingleStrapiResponse,
  StrapiCollectionEntry,
} from "dlp-types";

import { STRAPI_URL } from "../env";

const cache = new Cache();

export async function fetchDashboardCalculations(): Promise<
  Dashboard | undefined
> {
  try {
    const response = await cache.fetch(`${STRAPI_URL}/api/dashboard`, {
      credentials: "include",
    });

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    const json = (await response.json()) as Promise<Dashboard>;

    return json;
  } catch {
    return;
  }
}

export async function fetchOeProjectOverview(
  payload?: FilterData,
  reportingDate?: string
): Promise<OEProjectOverview | undefined> {
  try {
    const params = buildURLParameters(payload, reportingDate);

    const response = await cache.fetch(
      `${STRAPI_URL}/api/oe-project-overview?${params.join("&")}`,
      {
        credentials: "include",
      }
    );

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    const json = (await response.json()) as Promise<OEProjectOverview>;

    return json;
  } catch {
    return;
  }
}

export async function fetchProgram(): Promise<ProgramAttributes | undefined> {
  try {
    const response = await cache.fetch(`${STRAPI_URL}/api/program`, {
      credentials: "include",
    });

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    const json = (await response.json()) as Promise<ProgramAttributes>;

    return json;
  } catch {
    return;
  }
}

export async function fetchOEProjects(
  payload?: FilterData,
  reportingDate?: string
): Promise<OEProjectAttributes[]> {
  try {
    const params = buildURLParameters(payload, reportingDate);

    params.push(`pagination[limit]=100`);

    const response = await cache.fetch(
      `${STRAPI_URL}/api/oe-projects?${params.join("&")}`,
      {
        credentials: "include",
      }
    );

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    const json = (await response.json()) as OEProjectAttributes[];

    return json.sort((projectA, projectB) =>
      projectA.name.localeCompare(projectB.name)
    );
  } catch {
    return [];
  }
}
export async function fetchOEProjectsForNavigation(): Promise<
  OEProjectAttributes[]
> {
  try {
    const response = await cache.fetch(
      `${STRAPI_URL}/api/oe-projects/for-navigation`,
      {
        credentials: "include",
      }
    );

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    const json = (await response.json()) as OEProjectAttributes[];

    return json.sort((projectA, projectB) =>
      projectA.name.localeCompare(projectB.name)
    );
  } catch {
    return [];
  }
}

export async function fetchOEProject(
  projectId: number | string
): Promise<OEProjectAttributes | undefined> {
  try {
    const response = await cache.fetch(
      `${STRAPI_URL}/api/oe-projects/${projectId}`,
      {
        credentials: "include",
      }
    );

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    const json = (await response.json()) as OEProjectAttributes;

    return json;
  } catch {
    return;
  }
}

export async function updateOeProjectAndRelatedActivities(
  updateData: OEProjectAttributesForEditPage,
  idOfOEToUpdate: number | undefined
) {
  if (idOfOEToUpdate === undefined) {
    return;
  }
  try {
    const response = await cache.fetch(
      `${STRAPI_URL}/api/oe-projects/${idOfOEToUpdate}`,
      {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...updateData,
        }),
      }
    );
    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }
    const json = (await response.json()) as OEProjectAttributes;

    return json;
  } catch {
    return;
  }
}

function buildURLParameters(
  payload: FilterData | undefined,
  reportingDate: string | undefined
) {
  const params: string[] = [];

  if (payload?.searchQueryFilter) {
    params.push(
      `filterSearch=${encodeURIComponent(payload.searchQueryFilter)}`
    );
  }
  if (payload?.projectsFilter && payload?.projectsFilter.length != 0) {
    params.push(
      `filterProjects=${encodeURIComponent(payload.projectsFilter.join(","))}`
    );
  }
  if (payload?.govLeadFilter && payload?.govLeadFilter.length != 0) {
    params.push(
      `filterOwners=${encodeURIComponent(payload.govLeadFilter.join(","))}`
    );
  }
  if (payload?.atRiskFilter && payload?.atRiskFilter.length != 0) {
    params.push(
      `filterRisks=${encodeURIComponent(payload.atRiskFilter.join(","))}`
    );
  }
  if (payload?.overdueFilter && payload?.overdueFilter.length != 0) {
    params.push(
      `filterOverdues=${encodeURIComponent(payload.overdueFilter.join(","))}`
    );
  }
  if (payload?.localFilter && payload?.localFilter.length != 0) {
    params.push(
      `filterLocal=${encodeURIComponent(payload.localFilter.join(","))}`
    );
  }
  if (payload?.updateStatusFilter && payload?.updateStatusFilter.length != 0) {
    params.push(
      `filterUpdates=${encodeURIComponent(
        payload.updateStatusFilter.join(",")
      )}`
    );
  }
  if (payload?.maturityFilter && payload?.maturityFilter.length != 0) {
    params.push(
      `filterMaturity=${encodeURIComponent(payload.maturityFilter.join(","))}`
    );
  }
  if (payload?.regionFilter && payload?.regionFilter.length != 0) {
    params.push(
      `filterRegion=${encodeURIComponent(payload.regionFilter.join(","))}`
    );
  }
  if (reportingDate) {
    params.push(`reportingDate=${encodeURIComponent(reportingDate)}`);
  }
  if (payload?.dueYearFilter && payload?.dueYearFilter.length != 0) {
    params.push(
      `filterYear=${encodeURIComponent(payload.dueYearFilter.join(","))}`
    );
  }
  if (payload?.dueQuarterFilter && payload?.dueQuarterFilter.length != 0) {
    params.push(
      `filter_quarter=${encodeURIComponent(payload.dueQuarterFilter.join(","))}`
    );
  }
  return params;
}
export async function updateProgramReportingDate(reportingDate: string) {
  try {
    const response = await cache.fetch(`${STRAPI_URL}/api/program`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: { reportingDate },
      }),
    });
    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }
    const json = (await response.json()) as SingleStrapiResponse<
      StrapiCollectionEntry<ProgramAPIAttributes>
    >;
    return json;
  } catch {
    return;
  }
}
export async function fetchUserProfile(): Promise<Profile | undefined> {
  try {
    const response = await cache.fetch(`${STRAPI_URL}/keycloak/profile`, {
      credentials: "include",
    });

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    const json = (await response.json()) as Profile;
    return json;
  } catch {
    return;
  }
}

export async function uploadAttachments(input: FileList, oeProjectId?: string) {
  if (!input || !oeProjectId) {
    return;
  }
  const formData = new FormData();

  for (const file of input) {
    formData.append("files", file, file.name);
    formData.append("refId", oeProjectId);
  }

  try {
    const response = await cache.fetch(
      `${STRAPI_URL}/api/oe-projects/${oeProjectId}/attachments`,
      {
        method: "POST",
        credentials: "include",
        body: formData,
      }
    );
    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }
  } catch {
    return;
  }
}

export async function fetchAllAttachmentsForProject(oeProjectId?: string) {
  if (!oeProjectId) {
    return;
  }
  try {
    const url = `${STRAPI_URL}/api/oe-projects/${oeProjectId}/attachments`;

    const response = await cache.fetch(url, {
      method: "GET",
      credentials: "include",
    });

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    const json = (await response.json()) as Attachment[];

    return json;
  } catch {
    return;
  }
}
