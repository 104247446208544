import type { App, Plugin, Route, View } from "@pimo/pimo-app-builder";

import type { DLPAppState } from "../app";
import type { DashboardPlugin } from "./dashboard-plugin";
import type { ReportPlugin } from "./report-plugin";
import { GridLayout, GridLayoutProps } from "@pimo/pimo-components";
import { APP_ROUTES } from "../constants";
import { isUserAdmin } from "../helpers/is-user-admin";

export class PDFDashboardPlugin implements Plugin<DLPAppState, DLPAppState> {
  app?: App<DLPAppState>;
  route?: Route;
  view?: View<DLPAppState, GridLayoutProps>;
  isDashboardLoaded?: boolean;
  constructor(
    private dashboardPlugin: DashboardPlugin,
    private reportPlugin: ReportPlugin
  ) {}

  onRegister(app: App<DLPAppState>): void {
    this.app = app;

    const view = app.createView({
      name: "dashboard",
      layout: new GridLayout(),
    });
    this.view = view;
    this.route = app.createRoute({
      isRenderingAllChildren: true,
      path: APP_ROUTES.pdf,
      view,
    });
    this.route.on("load", () => {
      // check if `history.state` is populated.
      // `{ usr? {} }` is injected via `react-router-dom`.
      if ((window.history.state as { usr?: { print?: boolean } })?.usr?.print) {
        window.onafterprint = () => {
          if (window.location.pathname.includes("pdf")) {
            // eslint-disable-next-line
            // @ts-ignore - this is fine, `react-router-dom` supports `-1` to navigate to the previous page.
            app.navigate(-1, { state: null });
          }
        };
        // wait until the main thread is clear.
        // this also includes assets that are being fetched.
        window.requestIdleCallback(() => window.print(), { timeout: 1_000 });
      }
    });
  }

  render() {
    if (!this.app || !this.route || !this.view) {
      return;
    }
    const state = this.app.getAppState();
    if (isUserAdmin(state.userProfile) && !this.isDashboardLoaded) {
      this.dashboardPlugin.buildDashboardView(this.view);
      this.isDashboardLoaded = true;
    }

    if (this.route.children.length !== state?.oeProjects?.length) {
      this.route.children = [];

      for (const project of state?.oeProjects ?? []) {
        // for each oeProject create a new child route
        const view = this.app?.createView({
          name: `${project?.id ?? 0}`,
          layout: new GridLayout(
            1,
            isUserAdmin(state.userProfile) ||
              state.oeProjects?.indexOf(project) !== 0
          ),
        });

        this.reportPlugin.buildDashboardView(view, project.id);

        this.route.createChildRoute({
          path: `${APP_ROUTES.pdf}/${project?.id ?? 0}`,
          view,
        });
      }
    }
  }
}
