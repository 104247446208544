import { OEProjectAttributes } from "dlp-types";
import { Profile } from "dlp-types";

export function canUserAccessEditPage(
  profile: Profile | undefined,
  OEProject: OEProjectAttributes | undefined
): boolean {
  if (!profile || !OEProject) {
    return false;
  }
  if (profile.hasWriteRole) {
    return true;
  }
  return (
    profile.editableOes?.find((oe) => oe.oeName === OEProject.name)
      ?.hasWriteRole ?? false
  );
}
