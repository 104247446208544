import { Box, Typography } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";

import { Card, CardContent, LineGauge } from "../../molecules";

export interface SmallLineGaugeCardState {
  title: string;

  entries: {
    label: string;
    percentage: number;
    precision?: number;
    value: number;
    isTitle?: boolean;
  }[];
  scale: number[];
}

export const SmallLineGaugeCard: PimoReactComponent<
  SmallLineGaugeCardState
> = ({ entries = [], scale = [0, 100], title }) => {
  return (
    <Card
      title={title}
      leftSlot={
        <Box
          component="img"
          src={"maturity.svg"}
          sx={{
            backgroundColor: "white",
            borderRadius: 2,
          }}
        />
      }
    >
      <CardContent>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Box
            sx={{
              flexBasis: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              fontSize: "12px",
              fontWeight: "400",
              mb: 2,
              "@media print": { flexBasis: "50%" },
            }}
          >
            {scale.map((scaleEntry) => (
              <Typography key={`scale_${scaleEntry}`} sx={{ fontSize: "12px" }}>
                {scaleEntry}
              </Typography>
            ))}
          </Box>
        </Box>
        {entries.map((entry) => (
          <Box
            key={entry.label}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Box
              sx={{ flexBasis: "100%", "@media print": { flexBasis: "50%" } }}
            >
              <LineGauge
                percentage={entry.percentage}
                precision={entry.precision}
                value={entry.value}
              />
            </Box>
          </Box>
        ))}
      </CardContent>
    </Card>
  );
};

export default SmallLineGaugeCard;
