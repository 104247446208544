/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { Form, TextFormField } from "@pimo/pimo-components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Box,
} from "@mui/material";
import { grey } from "@mui/material/colors";

import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { styled } from "@mui/system";
import { OEProjectAttributesForEditPage } from "dlp-types";

export interface EditDashboardDialogProps {
  title: string;
  /** Data to populate the form */
  data: OEProjectAttributesForEditPage;
}

const DialogHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  display: "flex",
  justifyContent: "space-between",
  paddingRight: theme.spacing(2),
  overflowX: "clip",
  borderTopRightRadius: "12px",
  borderTopLeftRadius: "12px",
}));

export const EditDashboardDialog: PimoReactComponent<
  EditDashboardDialogProps,
  "edit-dialog:submit" | "edit-dialog:close",
  OEProjectAttributesForEditPage
> = ({ fireEvent, data, title }) => {
  const [open, setOpen] = useState(false);

  const formMethods = useForm<OEProjectAttributesForEditPage>({
    defaultValues: data,
  });

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">
          {"Close without saving?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              "Closing the dialog without saving will cause all the data to be lost."
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              fireEvent?.("edit-dialog:close");
            }}
          >
            Close
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            Continue Editing
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        sx={{
          marginTop: `env(safe-area-inset-top) !important`,
        }}
        PaperProps={{ sx: { borderRadius: "12px" } }}
        fullWidth={true}
        maxWidth={"lg"}
        open={true}
        onClose={() => setOpen(true)}
        data-testid="EditDialog"
      >
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <FormProvider {...formMethods}>
          <Form
            id="edit-dashboard-dialog-form"
            data-testid="EditDialogForm"
            /* eslint-disable @typescript-eslint/no-misused-promises */
            onSubmit={formMethods.handleSubmit((data) =>
              fireEvent?.("edit-dialog:submit", data)
            )}
            sx={{ maxHeight: 600, overflow: "auto" }}
          >
            <Typography variant="h5" sx={{ padding: 3 }}>
              {`Please Update the Executive Summary`}
            </Typography>

            <Box sx={{ py: 1, px: 4 }}>
              <TextFormField
                label={"Executive Summary"}
                name="executiveComment"
              />
            </Box>
          </Form>
        </FormProvider>
        <DialogActions>
          <Typography sx={{ fontSize: "0.8em", flex: "1 1 100%" }}>
            By saving you confirm that the information is up to date.
          </Typography>
          <Button sx={{ color: grey[500] }} onClick={() => setOpen(true)}>
            Cancel
          </Button>
          <Button
            id="save-button-scroll-into"
            variant="contained"
            type="submit"
            form={"edit-dashboard-dialog-form"}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
