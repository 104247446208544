import { Box, styled, Typography } from "@mui/material";
import { FC } from "react";
import { OEProjectAttributesForEditPage } from "dlp-types";
import { FormFields, FormRow, TextFormField } from "@pimo/pimo-components";

export interface EditKeyInformationFormContentsProps {
  data: OEProjectAttributesForEditPage;
  /** reporting date line from oe */
  reportingDate: string;
}

const FormHeader = styled(Box)(({ theme }) => ({
  paddingRight: theme.spacing(3),
  display: "flex",
  justifyContent: "space-between",
}));

/** KeyInformation update section of the edit dialog form
 * Should be wrapped in `form`, and `FormProvider<EditDialogData>` (for the date picker)
 */
export const EditKeyInformationFormContents: FC<
  EditKeyInformationFormContentsProps
> = ({ reportingDate, data }) => {
  return (
    <div data-testid="EditKeyInformationForm" style={{ overflowX: "auto" }}>
      <FormHeader>
        <Typography variant="h5" sx={{ padding: 3 }}>
          {`Please update your comment and Key Information${
            reportingDate ? ` (as of ${reportingDate})` : ""
          }`}
        </Typography>
      </FormHeader>

      <FormRow data-testid="KeyInfoFormRow">
        <FormFields>
          <TextFormField
            label={"Comment overall status"}
            name={`commentOverallStatus`}
            rows={10}
          />
          {data.dimension === "awareness" && (
            <TextFormField
              label={"Key Risks"}
              name={`keyRisksAwareness`}
              rows={10}
            />
          )}
          {data.dimension === "governance" && (
            <TextFormField
              label={"Key Risks"}
              name={`keyRisksGovernance`}
              rows={10}
            />
          )}
          {data.dimension === "processes" && (
            <TextFormField
              label={"Key Risks"}
              name={`keyRisksProcesses`}
              rows={10}
            />
          )}
          {data.dimension === "technology" && (
            <TextFormField
              label={"Key Risks"}
              name={`keyRisksTech`}
              rows={10}
            />
          )}
        </FormFields>
      </FormRow>
    </div>
  );
};
