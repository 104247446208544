export const APP_ROUTES = {
  dashboard: "/dashboard",
  login: "/login",
  overview: "/overview",
  report: "/reports",
  pdf: "/pdf",
} as const;

export const latestCompletionDate = {
  priority1: "2023-12-31",
  priority2: "2024-12-31",
  priority3: "2025-12-31",
};
