import { Search as SearchIcon } from "@mui/icons-material";
import {
  Box,
  Drawer,
  InputBase,
  styled,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { type FC, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import type { MenuEntryInterface } from "../../types/menu-type";
import { MenuList } from "../menu-list/menu-list";

export type SidebarSearchFunction = (
  items: MenuEntryInterface["items"],
  query: string
) => MenuEntryInterface["items"];

export const DEFAULT_SIDEBAR_SEARCH_FUNCTION: SidebarSearchFunction = (
  items,
  query
) =>
  items?.filter(({ title }) =>
    title.toLowerCase().includes(query.toLowerCase())
  );

export type SidebarProps = {
  /** (optional) set to true if searching the menu entries should be enabled */
  hasSearchFunctionality?: boolean;
  /** (optional) side bar elements */
  entries?: MenuEntryInterface[];
  /** (optional) custom search function */
  searchFunction?: SidebarSearchFunction;
  /** current status of sidebar (open/closed) */
  sidebarOpen: boolean;
  /** width of Sidebar in px */
  width: number;
};

const MenuSection = ({
  entries,
  hasSearchFunctionality,
  searchFunction = DEFAULT_SIDEBAR_SEARCH_FUNCTION,
}: Pick<
  SidebarProps,
  "entries" | "hasSearchFunctionality" | "searchFunction"
>) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const filteredEntries = useMemo<MenuEntryInterface[]>(() => {
    return (entries ?? []).map((entry: MenuEntryInterface) => ({
      ...entry,
      active: entry.link === pathname,
      items: searchFunction?.(entry.items, searchQuery),
    }));
  }, [entries, pathname, searchQuery]);

  return (
    <Box
      sx={{
        margin: {
          sm: "0 0 0 21px",
        },
        marginTop: `env(safe-area-inset-top) !important`,
      }}
    >
      {hasSearchFunctionality && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            border: "1px solid",
            borderRadius: "12px",
            borderColor: theme.palette.secondary.dark,
            backgroundColor: "#FAFAFA",
            "&:hover": {
              backgroundColor: "#FCFCFC",
            },
            mb: 1,
          }}
        >
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Search>
        </Box>
      )}
      <MenuList entries={filteredEntries} />
    </Box>
  );
};

/** Sidebar component used for navigation */
export const Sidebar: FC<SidebarProps> = ({
  entries = [],
  hasSearchFunctionality,
  searchFunction,
  sidebarOpen,
  width,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <aside data-testid="Sidebar">
      <Drawer
        variant={isMobile ? "temporary" : "persistent"}
        anchor="left"
        open={sidebarOpen}
        sx={{
          display: "block",
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: width,
            border: "none",
          },
        }}
      >
        <Toolbar sx={{ mb: "20px" }} />
        <MenuSection
          entries={entries}
          hasSearchFunctionality={hasSearchFunctionality}
          searchFunction={searchFunction}
        />
      </Drawer>
    </aside>
  );
};

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: "#FAFAFA",
  "&:hover": {
    backgroundColor: "#FCFCFC",
  },
  borderRadius: "12px",
  marginLeft: 0,
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
  width: "100%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#616161",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  paddingLeft: `calc(1em + ${theme.spacing(0.5)})`,
  "& .MuiInputBase-input": {
    padding: theme.spacing(2, 2, 2, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    width: "100%",
  },
}));
